import React from 'react';
import { Flex, Button } from "monday-ui-react-core";

const AddIntegrationInstructions = ({ closeAssistant }) => {
    return (
        <div>
            <hr />
            <Flex align={Flex.align.START} gap={32}>
                <div className="app-spirit-two-column">
                    <h2>Create your own integration</h2>
                    <span>Add an automation/integration to your board:</span>
                    <ol>
                        <li>Click on "Integrate" (top right corner)</li>
                        <li>Search for "Mail Sender" and select it</li>
                        <li>Select the integration recipe that suits your use case</li>
                        <li>
                            Fill in the parameters<br />
                            Example: Changing the Status to Signed, create a new document based on the recipe "TODO".
                        </li>
                        <li>Add the integration to your board. All done. If you now change the status/click a specific button or a certain date is reached, a document will automatically be created.</li>
                    </ol>
                    <Button kind={Button.kinds.SECONDARY} onClick={closeAssistant}>
                        Close
                    </Button>
                </div>
                <div className="app-spirit-two-column">
                    <p>&nbsp;</p>
                    <img className="responsive-image" src="/Screenshot_Step4_Instructions.png" alt="Add integration" />
                </div>
            </Flex>
        </div>
    );
};

export default AddIntegrationInstructions;
