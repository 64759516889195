import React, { Component } from 'react';
import mondaySdk from "monday-sdk-js";
import { Box } from "monday-ui-react-core";
import { Button } from "monday-ui-react-core";
import { LinearProgressBar } from "monday-ui-react-core";
import { Check } from "monday-ui-react-core/icons";
import { convertMondayFilterToGraphQLFilter } from './MondayFilterHelper';

const monday = mondaySdk();
monday.setApiVersion("2023-10");

export class Subscription extends Component {
    static displayName = Subscription.name;

    constructor(props) {
        super(props);
        this.state = {
            accountInfos: undefined,
            mondayFilterText: null,
            mondayFilterCopied: false,
        };
        this.userLanguage = 'en';
        this.mondayContext = window.mondayContext;
        if (this.mondayContext)
            this.userLanguage = this.mondayContext.user.currentLanguage;
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.copyFilterConditionButtonClick = this.copyFilterConditionButtonClick.bind(this);
        this.handleTitleClick = this.handleTitleClick.bind(this);  // TODO
        this.authenticateUserButtonClick = this.authenticateUserButtonClick.bind(this);
    }

    componentDidMount() {
        this.populateAccountData();

        monday.listen('filter', res => {
            //console.log(res);
            var mondayFilterText = null;
            if (res.data.rules && res.data.rules.length > 0) {
                mondayFilterText = convertMondayFilterToGraphQLFilter(res.data.rules);
            }
            this.setState({ mondayFilterText: mondayFilterText });
        });
    }

    async populateAccountData() {
        var timeZoneOffset = this.mondayContext.user.timeZoneOffset;
        if (timeZoneOffset === undefined)
            timeZoneOffset = 0;
        const response = await fetch('accountmanagement/AccountInfos?BoardId=' + this.mondayContext.boardId +
            '&UserCountryCode=' + this.mondayContext.user.countryCode +
            '&UserLanguageCode=' + this.mondayContext.user.currentLanguage +
            '&TimeZoneOffset=' + timeZoneOffset +
            '&SessionToken=' + window.mondaySessionToken);
        const data = await response.json();
        console.log(data);
        this.setState({ accountInfos: data, loading: false });
    }

    handleTitleClick() {
        console.log('handleTitleClick');

        // https://developer.monday.com/apps/docs/mondayexecute
        monday.execute('openPlanSelection', { isInPlanSelection: false });

//        monday.api(`
//mutation {
//  set_mock_app_subscription (
//       app_id: 10097574,
//       partial_signing_secret: "1d832647ac",
//       is_trial: true,
//       plan_id: "seat_5"
//  ) {
//    plan_id
//  }
//}`).then((result) => {
//            console.log('set_mock_app_subscription');
//            console.log(result);
//        });
    }

    async handleButtonClick() {
        console.log('handleButtonClick');

        // TODO: Parameter true or false?
        // https://developer.monday.com/apps/docs/mondayexecute
        monday.execute('openPlanSelection', { isInPlanSelection: true });
    }

    copyFilterConditionButtonClick() {
        var text = this.state.mondayFilterText;

        // Encode the text to Base64
        const base64EncodedText = btoa(text);

        // Use the Clipboard API to copy the encoded text to the clipboard
        navigator.clipboard.writeText(base64EncodedText).then(() => {
            console.log('Base64 encoded text copied to clipboard!');
            this.setState({ mondayFilterCopied: true });
            // Set a timer to revert the state back after 2 seconds
            setTimeout(() => {
                this.setState({ mondayFilterCopied: false });
            }, 2000);
        }).catch(err => {
            console.error('Failed to copy text to clipboard:', err);
        });

    //    monday.get("filter").then(res => console.log(res));
    //    monday.get("location").then(res => console.log(res));
    }

    authenticateUserButtonClick() {
        fetch('user/infos?UserId=' + this.mondayContext.user.id +
                '&AccountId=' + this.mondayContext.account.id +
                '&BoardId=' + this.mondayContext.boardId +
                "&SessionToken=" + window.mondaySessionToken).then((response) => {
                    response.json().then((data) => {
                        console.log(data);
                        window.location.assign(data.url);
                    });
                });
    }

    render() {
        if (this.state.accountInfos === undefined)
            return (<p><em>Loading...</em></p>);

        const title = this.props.isReportGenerator ? "Reports generated this month" : "Documents generated this month";
        const url = this.props.isReportGenerator ? "https://www.pdfreportgenerator.com" : "https://www.docexport.com";

        let copyItemFilterText = null;
        if (this.state.mondayFilterText) {
            copyItemFilterText = (
                <Button
                    kind={Button.kinds.SECONDARY}
                    successIcon={Check}
                    success={this.state.mondayFilterCopied}
                    onClick={this.copyFilterConditionButtonClick}>Copy filter condition</Button>
            );
        }
        return (
            <div>
                {/*<h3 onClick={this.handleTitleClick}>Subscription</h3>*/}
                <div>
                    <Box
                        className="app-spirit-subscription-box"
                        border={Box.borders.DEFAULT}
                        shadow={Box.shadows.MEDIUM}
                        rounded={Box.roundeds.MEDIUM}>
                        <h2 onClick={this.handleTitleClick}>{title}</h2>
                        <LinearProgressBar
                            className="linear-progress-bar_small-wrapper"
                            size="large"
                            max={this.state.accountInfos.totalCount}
                            value={this.state.accountInfos.documentCount}
                        />
                        <span>{this.state.accountInfos.documentCount} / {this.state.accountInfos.totalCount}</span><br /><br />
                        <span>Your plan: {this.state.accountInfos.planName}</span><br />
                        {/*{this.state.accountInfos.nextRenewal && <span>Renews on {this.state.accountInfos.nextRenewal}</span>}*/}
                        <br />
                        <Button kind={Button.kinds.PRIMARY} onClick={this.handleButtonClick}>Change plan</Button>
                    </Box>
                    <p>For more information, visit <a href={url} target="_blank" rel="noreferrer">{url}</a></p>
                    <p><br /></p>
                    <Button
                        kind={Button.kinds.SECONDARY}                                                
                        onClick={this.authenticateUserButtonClick}>Authorize app</Button>
                    {copyItemFilterText}
                </div>
            </div>
        );
    }
}
