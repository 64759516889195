import React from 'react';
import { Flex, Checkbox, Dropdown, ExpandCollapse, TextField, Button, Box } from "monday-ui-react-core";

const RenderSelectActionsStep = ({ state, handleStateChange, handleActionsNextButtonClick, closeAssistant }) => {
    const handleCheckboxChange = (key) => {
        handleStateChange(key, !state[key]);
    };

    const handleDropdownChange = (key, selectedOption) => {
        handleStateChange(key, selectedOption);
    };

    const handleTextFieldChange = (key, text) => {
        handleStateChange(key, text);
    };

    const renderSendEmailToColumnSelect = () => {
        if (!state.sendEmailColumns) return undefined;
        return (
            <Dropdown
                className="app-spirit-dropdown"
                onChange={(selectedOption) => handleDropdownChange('selectedSendEmailColumn', selectedOption)}
                value={state.selectedSendEmailColumn}
                options={state.sendEmailColumns}
                clearable={false}
                placeholder=""
            />
        );
    };

    const renderSendEmailToCustomAddressesInput = () => {
        if (!state.sendEmailToCustomAddresses) return undefined;
        return (
            <TextField
                wrapperClassName="app-spirit-textfield-emailaddresses"
                placeholder="Email addresses separated by semicolon"
                value={state.customEmailAddresses}
                onChange={(txt) => handleTextFieldChange('customEmailAddresses', txt)}
            />
        );
    };

    const sendEmailToColumnSelect = renderSendEmailToColumnSelect();
    const sendEmailToCustomAddressesInput = renderSendEmailToCustomAddressesInput();

    return (
        <Flex align={Flex.align.START} gap={32}>
            <div className="app-spirit-two-column">
                <p>Who should receive the email?</p>
                <Flex>
                    <Checkbox className="app-spirit-checkbox" onChange={() => handleCheckboxChange('sendEmailToMe')} checked={state.sendEmailToMe}
                        label="Send email to user who starts the integration"
                    />
                </Flex>
                <Flex className="app-spirit-flex">
                    <Checkbox className="app-spirit-checkbox" disabled={state.hasNoSendEmailColumn} onChange={() => handleCheckboxChange('sendEmailToColumn')} checked={state.sendEmailToColumn}
                        label="Send email to email address(es) in column:"
                    />
                    {sendEmailToColumnSelect}
                </Flex>
                <Checkbox className="app-spirit-checkbox" onChange={() => handleCheckboxChange('sendEmailToCustomAddresses')} checked={state.sendEmailToCustomAddresses}
                    label="Send email to email address(es)"
                />
                <Flex className="app-spirit-flex">
                    {sendEmailToCustomAddressesInput}
                </Flex>
                {state.fileToColumns.length > 0 && (
                    <div>
                        <p><br /></p>
                        <ExpandCollapse
                            className=""
                            open={true}
                            title="Advanced settings">
                            <Flex className="app-spirit-flex">
                                <Checkbox className="app-spirit-checkbox" onChange={() => handleCheckboxChange('attachFilesFromColumn')} checked={state.attachFilesFromColumn}
                                    label="Additionally attach files to email from:"
                                />
                                <Dropdown
                                    className="app-spirit-dropdown"
                                    onChange={(selectedOption) => handleDropdownChange('selectedAttachmentColumn', selectedOption)}
                                    value={state.selectedAttachmentColumn}
                                    options={state.fileToColumns}
                                    clearable={false}
                                    placeholder=""
                                />
                            </Flex>
                        </ExpandCollapse>
                    </div>
                )}
                <p><br /></p>
                <Flex>
                    <Button loading={state.isSavingRecipe} onClick={handleActionsNextButtonClick}>
                        Next
                    </Button>
                    <Button className="app-spirit-cancelbutton" kind={Button.kinds.SECONDARY} onClick={closeAssistant}>
                        Cancel
                    </Button>
                </Flex>
            </div>
            <div className="app-spirit-two-column">
                <Box backgroundColor={Box.backgroundColors.GREY_BACKGROUND_COLOR} border={Box.borders.DEFAULT} padding={Box.paddings.LARGE} rounded={Box.roundeds.MEDIUM}>
                    <p className="app-spirit-explanation-text-top">How do I work with actions?</p>
                    <p>TODO</p>
                    <p className="app-spirit-explanation-text-bottom">Additionally, you can
                        <ol>
                            <li>TODO</li>
                            <li>Send it to the email address(es) contained in a specific column.<br />For example, if your board contains a column with the email address of your customer, you can select this column from the dropdown, so that the customer receives the document via email.</li>
                            <li>Send it to others via email by adding one or more email addresses manually, separated by a semicolon (e.g. hello@company.com; tom@company.com)<br /></li>
                        </ol>
                    </p>
                </Box>
            </div>
        </Flex>
    );
};

export default RenderSelectActionsStep;
