import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Button } from "monday-ui-react-core";

function FileUpload({ Destination = '', onUploadComplete, isExecuting, buttonText, buttonSize, buttonKind }) {
    const [uploadStatus, setUploadStatus] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const fileInputRef = useRef(null);
    
    var templateSizeMB = window.towerAppInfos?.templateSizeMB;
    //console.log(window.towerAppInfos);
    if (!templateSizeMB)
        templateSizeMB = 2;
    //console.log('TemplSize ' + templateSizeMB);
    const MAX_FILE_SIZE = templateSizeMB * 1048576; // 1 MB = 1,048,576 bytes

    const resetInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const onUpload = async (fileToUpload) => {
        setIsUploading(true);
        const formData = new FormData();
        formData.append('file', fileToUpload);

        var mondayContext = window.mondayContext;

        try {
            const response = await axios.post("template/upload?BoardId=" + mondayContext.boardId +
                "&WorkspaceId=" + mondayContext.workspaceId +
                "&Destination=" + Destination +
                "&SessionToken=" + window.mondaySessionToken, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log(response);
            if (response.status === 200) {
                setUploadStatus('File uploaded successfully!');
                if (onUploadComplete) {
                    onUploadComplete(true, 'File uploaded successfully!', response.data); // Invoke the callback on success
                }
            } else {
                setUploadStatus('File upload failed.');
                if (onUploadComplete) {
                    onUploadComplete(false, 'File upload failed.'); // Invoke the callback on failure
                }
            }
        } catch (error) {
            setUploadStatus(`Error: ${error.message}`);
            if (onUploadComplete) {
                onUploadComplete(false, `Error: ${error.message}`); // Invoke the callback on error
            }
        } finally {
            setIsUploading(false);
            resetInput(); // Reset the file input after upload
        }
    };

    const onFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return; // Early return if no file is selected

        // Check file type
        if (!selectedFile.name.endsWith('.docx')) {
            const errorMessage = 'Only .docx files are allowed.';
            setUploadStatus(errorMessage);
            if (onUploadComplete) {
                onUploadComplete(false, errorMessage);
            }
            resetInput(); // Reset the file input if file type is incorrect
            return;
        }

        // Check if the file size exceeds the limit
        if (selectedFile.size > MAX_FILE_SIZE) {
            const errorMessage = 'File size should not exceed ' + templateSizeMB + ' MB.';
            setUploadStatus(errorMessage);
            if (onUploadComplete) {
                onUploadComplete(false, errorMessage);
            }
            resetInput(); // Reset the file input if file size exceeds limit
            return;
        }

        onUpload(selectedFile); // Trigger the file upload immediately after a file is selected
    };

    const onButtonClick = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    //console.log('IsExecuting ' + isExecuting);

    return (
        <div>
            <input
                type="file"
                accept=".docx"
                onChange={onFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
            />
            <Button loading={isUploading || isExecuting} kind={buttonKind} size={buttonSize} onClick={onButtonClick}>
                {buttonText ?? 'Upload new template'}
            </Button>
            {uploadStatus && <p>{uploadStatus}</p>}
        </div>
    );
}

export default FileUpload;