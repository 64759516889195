import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Flex, Button, EditableHeading, TextField, Tooltip } from "monday-ui-react-core";
import JoditEditor from 'jodit-react';
import axios from 'axios';
import DOMPurify from 'dompurify';

const TemplateEdit = ({ id, isMailSender, onClose }) => {
	const editor = useRef(null);	
	const [content, setContent] = useState('');
	const [subject, setSubject] = useState('');
	const [templateName, setTemplateName] = useState('Template Name');
	const [senderName, setSenderName] = useState('');
	const [senderEmailAddress, setSenderEmailAddress] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [error, setError] = useState(null);
	const [isValid, setIsValid] = useState(true);

	const config = useMemo(() => ({
		readonly: false, // all options from https://xdsoft.net/jodit/docs/,
		height: 400,
		buttons: ['paragraph', 'bold', 'underline', 'italic', '|', 'ul', 'ol', '|', 'image', 'video', 'table', 'link', '|', 'align', 'undo', 'redo', '|', 'source'],
		placeholder: 'Email body...', // Removed dynamic placeholder, using a fixed string
		addNewLine: false
	}), []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(true);
				const response = await axios.get(`template?id=${id}&sessionToken=${window.mondaySessionToken}`);
				// Assuming the response has data with content, subject, and templateName
				setContent(response.data.content);
				setSubject(response.data.emailSubject);
				setTemplateName(response.data.name);
				setSenderName(response.data.senderName);
				setSenderEmailAddress(response.data.senderEmailAddress);
				setIsLoading(false);
			} catch (err) {
				setError(err);
				setIsLoading(false);
			}
		};

		if (id) {
			fetchData();
		}
	}, [id]);

	const handleTemplateNameChange = (text) => {
		setTemplateName(text);
	};

	const handleSenderNameChange = (text) => {
		setSenderName(text);
	};

	const handleSenderEmailAddressChange = (text) => {
		setSenderEmailAddress(text);
		if (text) {
			const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
			setIsValid(emailRegex.test(text));
		}
		else
			setIsValid(true);
	};

	const handleSubjectChange = (text) => {
		setSubject(text);
    }

	const getDataForPOST = () => {
		var mondayContext = window.mondayContext;
		const data = {
			id: id,
			accountId: mondayContext.account?.id,
			boardId: mondayContext.boardId,
			workspaceId: mondayContext.workspaceId,
			userCountryCode: mondayContext.user.countryCode,
			userLanguage: mondayContext.user.currentLanguage,
			sessionToken: window.mondaySessionToken,
			name: DOMPurify.sanitize(templateName),
			emailSubject: DOMPurify.sanitize(subject),
			senderName: DOMPurify.sanitize(senderName),
			senderEmailAddress: DOMPurify.sanitize(senderEmailAddress),
			content: DOMPurify.sanitize(content)
		};
		return data;
	}

	const handleClickSave = async () => {		
		setIsSaving(true);
		const data = getDataForPOST();
		try {
			const response = await axios.post('template', data);
			console.log(response.data);
			setIsSaving(false);
			if (response.data.result) {
				onClose(response.data.template);
			} else {
				var errorMessage = 'Error saving template: ' + response.data.error;
				console.log(errorMessage);
				alert(errorMessage);
			}
		} catch (error) {
			console.error('Error:', error);
			alert(error);
			setIsSaving(false);
		}
	};

	const handleCancelClick = () => {
		onClose();
	};

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>Error: {error.message}</div>;
	}

    var validation = undefined;
    if (!isValid)
        validation = {
            status: "error",
            text: "Please enter a valid email address"
		};

	var hasHeader = true;
	var hasCancelButton = true;
	var saveButtonText = 'Save';
	var subjectPlaceholder = "New document for you";
	if (isMailSender) {
		hasHeader = false;
		hasCancelButton = false;
		saveButtonText = 'Next';
		subjectPlaceholder = '';
	}

	return (
		<div>
			{hasHeader && <>
				<Tooltip content="Click to Edit">
					<EditableHeading
						onChange={handleTemplateNameChange}
						type="h2"
						value={templateName}
					/>
				</Tooltip>
				<p></p>
			</>}
			<TextField				
				placeholder={subjectPlaceholder}
				title="Email subject"
				value={subject}
				onChange={handleSubjectChange}
			/>			
			<Flex gap={8} className="app-spirit-sender-flex">
				<TextField					
					placeholder="John Doe"
					title="Email sender name (optional)"
					value={senderName}
					onChange={handleSenderNameChange}
				/>
				<TextField					
					placeholder="yourname@domain.com"
					title="Email sender address (optional)"
					type="email"
					value={senderEmailAddress}
					onChange={handleSenderEmailAddressChange}
					validation={validation}
				/>
			</Flex>
			<p></p>
			<JoditEditor
				ref={editor}
				value={content}
				config={config}
				tabIndex={1}
				onBlur={newContent => setContent(newContent)}
				onChange={newContent => { }}
			/>
			<p></p>
			<Button disabled={!isValid} loading={isSaving} onClick={handleClickSave}>
				{saveButtonText}
			</Button>
			{hasCancelButton && <Button kind={Button.kinds.SECONDARY} className="app-spirit-cancelbutton" onClick={handleCancelClick}>
				Cancel
			</Button>}
		</div>
	);
};

export default TemplateEdit;