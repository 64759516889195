import React, { useEffect } from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';
import TemplateEdit from './TemplateEdit';
import { loadItemsForSelection } from './../utils';
import RenderSelectActionsStep from './MailSender/RenderSelectActionsStep';
import RenderInstructionsStep from './MailSender/RenderInstructionsStep';
import useGenerateEmailState from './MailSender/useGenerateEmailState';
import monday from './../services/monday/monday';
import { loadBoardData } from './../services/loadBoardData';
import { startWaitForDataRequest } from './../services/startWaitForDataRequest';

const GenerateEmail = (props) => {
    const mondayContext = window.mondayContext;
    const [state, setState] = useGenerateEmailState(props, mondayContext);

    useEffect(() => {
        const fileToColumnsRaw = props.columns.filter(c => c.type === 'file');
        const fileToColumns = fileToColumnsRaw.map(c => ({ value: c.id, label: c.title }));
        const sendEmailColumnsRaw = props.columns.filter(c => c.type === 'email' || c.type === 'people' || c.mirrorType === 'email');
        const sendEmailColumns = sendEmailColumnsRaw.map(c => ({ value: c.id, label: c.title }));

        setState(prevState => ({
            ...prevState,
            fileToColumns,
            hasNoFileToColumn: fileToColumns.length === 0,
            sendEmailColumns,
            hasNoSendEmailColumn: sendEmailColumns.length === 0,
        }));
    }, [props.columns, setState]);

    useEffect(() => {
        loadItemsForSelection(mondayContext, setState);
    }, [mondayContext, setState]);

    const handleStateChange = (key, value) => {
        setState(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleNewTemplateClose = (template) => {
        if (template) {
            handleStateChange('mode', 'actions');
            handleStateChange('mailTemplateId', template.id);
            props.onAssistantModeChanges(true);
        }
    };

    const generateForItemButtonClick = async () => {
        handleStateChange('executing', true);
        monday.execute("valueCreatedForUser");

        monday.execute("notice", {
            message: "Please don't close this tab while sending the email.",
            type: "info", // or "error" (red), or "info" (blue)
            timeout: 3000,
        });

        var data = {
            recipeId: state.recipeId,
            accountId: mondayContext.account?.id,
            boardId: mondayContext.boardId,
            userId: mondayContext.user.id,
            workspaceId: mondayContext.workspaceId,
            userCountryCode: mondayContext.user.countryCode,
            userLanguage: mondayContext.user.currentLanguage,
            userTimeZoneOffset: mondayContext.user.timeZoneOffset,
            userTimeFormat: mondayContext.user.timeFormat,
            sessionToken: window.mondaySessionToken,
            documentType: 'None'
        };
        var boardData = await loadBoardData(mondayContext, 'SingleItem', state.selectedItem, false, undefined);
        if (boardData)
            data.boardData = boardData.data;

        startWaitForDataRequest(mondayContext.boardId, data.requestId);

        try {
            const response = await axios.post('document/generateDocument', data);
            //console.log(response.data);
            if (response.data.result) {
                handleStateChange('executing', false);
                //this.setState({ executing: false, documentContent: response.data.document, documentError: undefined });
                //localStorage.removeItem(this.localStorageStateIdentifier);
                //this.props.onAssistantModeChanges(false, response.data.documentLimitReached);
                //if (this.state.dataSource === "SingleItem" && this.state.fileToColumn && this.state.selectedFileToColumn) {
                //    this.uploadFileToMonday(response.data);
                //}
            } else {                
                var errorMessage = 'Error generating document: ' + response.data.error;
                console.log(errorMessage);
                if (response.data.documentLimitReached) {
                    alert(errorMessage);
                    handleStateChange('executing', false);
                }
                else {
                    handleStateChange('executing', false);  // TODO
                    //this.setState({ executing: false, documentError: response.data, documentContent: undefined });
                }
            }
        } catch (error) {
            // TODO: Send error to server
            console.error('Error:', error);
            alert(error);
            handleStateChange('executing', false);
        }
    }

    const getDataForPOST = () => {
        const sendEmailColumnId = state.sendEmailToColumn ? state.selectedSendEmailColumn?.value : '';
        const sendEmailCustomAddresses = state.sendEmailToCustomAddresses ? state.customEmailAddresses : '';
        const attachmentColumnId = state.attachFilesFromColumn ? state.selectedAttachmentColumn?.value : '';

        return {
            accountId: mondayContext.account?.id,
            boardId: mondayContext.boardId,
            userId: mondayContext.user.id,
            workspaceId: mondayContext.workspaceId,
            userCountryCode: mondayContext.user.countryCode,
            userLanguage: mondayContext.user.currentLanguage,
            userTimeZoneOffset: mondayContext.user.timeZoneOffset,
            userTimeFormat: mondayContext.user.timeFormat,
            sessionToken: window.mondaySessionToken,
            documentType: 'None',
            sendEmailToMe: state.sendEmailToMe,
            sendEmailToColumn: state.sendEmailToColumn,
            sendEmailToColumnId: DOMPurify.sanitize(sendEmailColumnId),
            sendEmailToColumnName: DOMPurify.sanitize(state.selectedSendEmailColumn?.label),
            sendEmailToCustomAddresses: state.sendEmailToCustomAddresses,
            emailCustomAddresses: DOMPurify.sanitize(sendEmailCustomAddresses),
            sendEmailToMeTemplateId: DOMPurify.sanitize(state.mailTemplateId),
            sendEmailToColumnTemplateId: DOMPurify.sanitize(state.mailTemplateId),
            sendEmailToCustomAddressesTemplateId: DOMPurify.sanitize(state.mailTemplateId),
            emailAttachmentColumnId: DOMPurify.sanitize(attachmentColumnId),
        };
    };

    const handleActionsNextButtonClick = async () => {
        handleStateChange('isSavingRecipe', true);
        const data = getDataForPOST();
        try {
            const response = await axios.post('recipe/createNew', data);
            console.log('Success:', response.data);
            handleStateChange('isSavingRecipe', false);
            handleStateChange('recipeId', response.data.id);
        } catch (error) {
            console.error('Error:', error);
            handleStateChange('isSavingRecipe', false);
        }

        handleStateChange('mode', 'instructions');
    };

    const closeAssistant = () => {
        setState({
            mode: 'template',
            mailTemplateId: null,
            sendEmailToMe: false,
            sendEmailToColumn: false,
            sendEmailToCustomAddresses: false,
            customEmailAddresses: '',
            selectedAttachmentColumn: undefined,
            attachFilesFromColumn: false,
            selectedSendEmailColumn: undefined,
            recipeId: undefined,
            isSavingRecipe: false,
            itemsForSelection: [],
        });
        props.onAssistantModeChanges(false);
    };

    const renderContent = () => {
        if (state.mode === 'template') {
            return (
                <div>
                    <TemplateEdit id={state.mailTemplateId} isMailSender={true} onClose={handleNewTemplateClose}></TemplateEdit>
                </div>
            );
        }

        if (state.mode === 'actions')
            return <RenderSelectActionsStep state={state} handleStateChange={handleStateChange} handleActionsNextButtonClick={handleActionsNextButtonClick} closeAssistant={closeAssistant} />;

        if (state.mode === 'instructions')
            return <RenderInstructionsStep state={state} handleStateChange={handleStateChange} generateForItemButtonClick={generateForItemButtonClick} closeAssistant={closeAssistant} />;

        return (<div></div>);
    };

    if (mondayContext.user.isViewOnly) {
        return (
            <p>I am sorry, but you don't have write rights in monday. This app requires write rights.</p>
        );
    }

    return (
        <div>
            {renderContent()}
        </div>
    );
};

export default GenerateEmail;