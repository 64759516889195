import React, { Component } from 'react';
import { Button, Link } from "monday-ui-react-core";
import { IconButton } from "monday-ui-react-core";
import { Delete } from "monday-ui-react-core/icons";
import axios from 'axios';
import { Table, TableCell, TableBody, TableHeader, TableHeaderCell, TableRow } from "monday-ui-react-core";
import TriggerDefinitionEdit from './TriggerDefinitionEdit';
import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();
monday.setApiVersion("2023-10");

const TableEmptyState = () => <h1 style={{ textAlign: "center" }}>Empty State</h1>;

const TableErrorState = () => <h1 style={{ textAlign: "center" }}>Error State</h1>;

export class TriggerDefinitions extends Component {
    static displayName = TriggerDefinitions.name;

    constructor(props) {
        super(props);
        this.state = {
            triggerDefinitions: [],
            triggerEditMode: false,
            triggerEditId: null,
            loading: true,
            isBoardOwner: props.isBoardOwner
        };
        this.mondayContext = window.mondayContext;
        this.towerAppInfos = window.towerAppInfos;
        this.handleClickCreateNewTriggerDefinition = this.handleClickCreateNewTriggerDefinition.bind(this);
        this.handleTriggerEditClose = this.handleTriggerEditClose.bind(this);
        this.handleUpgradeClick = this.handleUpgradeClick.bind(this);
        this.handleEditTrigger = this.handleEditTrigger.bind(this);
    }

    componentDidMount() {
        //console.log(this.context);
        if (!this.towerAppInfos)
            return;
        this.populateTriggerDefinitionData();
    }

    async handleEditTrigger(event, id) {
        if (!this.state.isBoardOwner)
            return;
        if (!this.towerAppInfos || this.towerAppInfos.hasTriggers === false) {
            alert('Please upgrade to the Professional plan to use triggers!');
            return;
        }
        this.setState({ triggerEditMode: true, triggerEditId: id });
    }

    handleTriggerEditClose(triggerDefinition, isAlreadyExisting) {
        if (isAlreadyExisting) {
            monday.execute("notice", {
                message: 'This trigger definition already exists with name ' + triggerDefinition.name,
                type: "info", // or "error" (red), or "info" (blue)
                timeout: 6000,
            });
        }
        this.setState({ triggerEditMode: false, triggerEditId: null });
        this.populateTriggerDefinitionData();
    }

    renderNewTable(triggerDefinitions) {

        var columns = [{
            id: "name",
            title: "Name",
            width: {
                min: 120,
                max: 2500
            },
            loadingStateType: "long-text"
        }, {
            id: "placeholder",
            title: "Placeholder",
            width: {
                min: 350,
                max: 2500
            },
            loadingStateType: "long-text"
        }, {
            id: "emailPlaceholder",
            title: "Email template",
            width: {
                min: 350,
                max: 2500
            },
            loadingStateType: "long-text"
        }, {
            id: "lastUsedOn",
            title: "Last used",
            width: 140,
            //infoContent: "Info content for the status column",
            loadingStateType: "medium-text"
        }, {
            id: "edit",
            title: "",
            width: 50,
            loadingStateType: "circle"
        }, {
            id: "delete",
            title: "",
            width: 40,
            loadingStateType: "circle"
        }];

        var tableDataState = {
            isLoading: this.state.loading
        };

        return (
            <Table errorState={<TableErrorState />} emptyState={<TableEmptyState />} columns={columns} dataState={tableDataState}>
                <TableHeader>
                    {columns.map((headerCell, index) => <TableHeaderCell key={index} title={headerCell.title} />)}
                </TableHeader>
                <TableBody>
                    {triggerDefinitions.map(rowItem => <TableRow key={rowItem.id}>
                        <TableCell>{rowItem.name}</TableCell>
                        <TableCell>{rowItem.placeholder}</TableCell>
                        <TableCell>{rowItem.emailPlaceholder}</TableCell>
                        <TableCell>{rowItem.lastUsedOn}</TableCell>
                        <TableCell className="app-spirit-tablecell-with-button" >
                                <Button
                                    kind={Button.kinds.SECONDARY}
                                    size={Button.sizes.SMALL}
                                    onClick={(e) => this.handleEditTrigger(e, rowItem.id)}>Edit</Button>                            
                        </TableCell>
                        <TableCell className="app-spirit-tablecell-with-button" >
                            {this.state.isBoardOwner && (
                                <IconButton
                                    ariaLabel="Delete"
                                    size={IconButton.sizes.SMALL}
                                    icon={Delete}
                                    onClick={(e) => this.deleteTriggerDefinition(e, rowItem.id)}
                                />
                            )}
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        );
    }

    async deleteTriggerDefinition(event, id) {
        console.log('Delete trigger definition ' + id);
        try {
            const response = await axios.delete('trigger?Id=' + id + '&SessionToken=' + window.mondaySessionToken);
            console.log('Success:', response.data);
            await this.populateTriggerDefinitionData();
        } catch (error) {
            console.error('Error:', error);
        }
    }

    handleClickCreateNewTriggerDefinition() {
        this.setState({ triggerEditMode: true, triggerEditId: null });
    }

    handleUpgradeClick(event) {
        console.log('Upgrade now');
        event.preventDefault();
        // https://developer.monday.com/apps/docs/mondayexecute
        monday.execute('openPlanSelection', { isInPlanSelection: false });
    }

    render() {
        const url = "https://www.docexport.com/help/template/trigger/";
        if (!this.towerAppInfos || this.towerAppInfos.hasTriggers === false) {
            return (<div>
                <span>Triggers are only available for Professional accounts. Please upgrade!</span>
                <Button className="app-spirit-cancelbutton" onClick={this.handleUpgradeClick}>
                    Upgrade now
                </Button>
                <p></p>
                <a href={url} target="_blank" rel="noreferrer">Learn more</a>
            </div>);
        }

        if (this.state.triggerEditMode) {
            return (
                <TriggerDefinitionEdit
                    id={this.state.triggerEditId}
                    columns={this.props.columns}
                    subItemsColumns={this.props.subItemsColumns}
                    onClose={this.handleTriggerEditClose}></TriggerDefinitionEdit>
            );
        }
        let contents = this.renderNewTable(this.state.triggerDefinitions);

        return (
            <div>
                <h3 id="tableLabel">Triggers</h3>
                <Button onClick={this.handleClickCreateNewTriggerDefinition}>
                    Create new trigger
                </Button>
                <p>                    
                </p>
                {contents}
                <p><Link href={url} target="_blank" rel="noreferrer" text="Learn how Triggers work" /></p>
            </div>
        );
    }

    async populateTriggerDefinitionData() {
        var timeZoneOffset = this.mondayContext.user.timeZoneOffset;
        if (timeZoneOffset === undefined)
            timeZoneOffset = 0;
        const response = await fetch('trigger/list?BoardId=' + this.mondayContext.boardId +
            '&TimeZoneOffset=' + timeZoneOffset +
            '&SessionToken=' + window.mondaySessionToken);
        const data = await response.json();
        //console.log(data);
        this.setState({ triggerDefinitions: data, loading: false });
    }
}
