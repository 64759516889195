import React from 'react';
import { Flex, Dropdown, Button } from "monday-ui-react-core";
import AddIntegrationInstructions from './AddIntegrationInstructions';

const RenderInstructionsStep = ({ state, handleStateChange, generateForItemButtonClick, closeAssistant }) => {
    return (
        <div>
            <h4>You successfully created the email recipe 🎉</h4>
            <div className="app-spirit-little-space-bottom">You can send an email directly here to preview your settings:</div>
            <Flex>
                <Dropdown
                    className="app-spirit-dropdown-wide"
                    onChange={(selectedOption) => handleStateChange('selectedItem', selectedOption)}
                    value={state.selectedItem}
                    options={state.itemsForSelection}
                    clearable={false}
                    placeholder='Please select an item'
                />
                <Button disabled={!state.selectedItem} loading={state.executing} onClick={generateForItemButtonClick}>
                    Send email
                </Button>
            </Flex>
            <br />
            <AddIntegrationInstructions closeAssistant={closeAssistant} />
        </div>
    );
};

export default RenderInstructionsStep;
