import axios from 'axios';
import monday from './monday/monday';
import { MONDAY_API_VERSION } from './../constants/constants';

export function startWaitForDataRequest(boardId, requestId, queryResult = undefined) {
    const waitForDataRequestData = {
        boardId: boardId,
        sessionToken: window.mondaySessionToken,
        requestId: requestId,
        queryResult: JSON.stringify(queryResult)
    };

    // Start the async operation in the background
    (async () => {
        try {
            const response = await axios.post('document/WaitForDataRequest', waitForDataRequestData);
            console.log(response);

            if (response.data.action === 'Query') {
                try {
                    const queryResponse = await monday.api(response.data.query, { apiVersion: MONDAY_API_VERSION });
                    console.log(queryResponse);
                    await startWaitForDataRequest(boardId, requestId, queryResponse.data);
                } catch (error) {
                    console.error('API call failed:', error);
                    let errorMessage = 'Default error message';
                    if (error) {
                        errorMessage = JSON.stringify(error, null, 2);
                    }
                    console.error('Error message:', errorMessage);

                    const errorInDataRequestData = {
                        boardId: boardId,
                        sessionToken: window.mondaySessionToken,
                        requestId: requestId,
                        errorMessage: errorMessage,
                    };

                    try {
                        const errorResponse = await axios.post('document/ErrorInDataRequest', errorInDataRequestData);
                        console.log('Error reported to server:', errorResponse.data);
                        await startWaitForDataRequest(boardId, requestId, queryResult.data);
                    } catch (serverError) {
                        console.error('Failed to report error to server:', serverError);
                    }
                }
            }
        } catch (error) {
            console.error('Error with WaitForDataRequest:', error);
        }
    })();
}
