import { useState, useEffect } from 'react';
import { loadItemsForSelection } from '../../utils';

const useGenerateEmailState = (props, mondayContext) => {
    const [state, setState] = useState({
        mode: 'template',
        mailTemplateId: null,
        fileToColumns: [],
        hasNoFileToColumn: false,
        sendEmailToMe: false,
        sendEmailToColumn: false,
        sendEmailColumns: [],
        hasNoSendEmailColumn: false,
        selectedSendEmailColumn: undefined,
        sendEmailToCustomAddresses: false,
        customEmailAddresses: '',
        selectedAttachmentColumn: undefined,
        attachFilesFromColumn: false,
        itemsForSelection: [],
        selectedItem: undefined,
        executing: false,
        isSavingRecipe: false,
        recipeId: undefined,
    });

    useEffect(() => {
        const fileToColumnsRaw = props.columns.filter(c => c.type === 'file');
        const fileToColumns = fileToColumnsRaw.map(c => ({ value: c.id, label: c.title }));
        const sendEmailColumnsRaw = props.columns.filter(c => c.type === 'email' || c.type === 'people' || c.mirrorType === 'email');
        const sendEmailColumns = sendEmailColumnsRaw.map(c => ({ value: c.id, label: c.title }));

        setState(prevState => ({
            ...prevState,
            fileToColumns,
            hasNoFileToColumn: fileToColumns.length === 0,
            sendEmailColumns,
            hasNoSendEmailColumn: sendEmailColumns.length === 0,
        }));
    }, [props.columns]);

    useEffect(() => {
        loadItemsForSelection(mondayContext, setState);
    }, [mondayContext]);

    return [state, setState];
};

export default useGenerateEmailState;