//import mondaySdk from "monday-sdk-js";
import monday from './services/monday/monday';
import { MONDAY_API_VERSION } from './constants/constants';

export const loadItemsForSelection = async (mondayContext, setState) => {
    if (!mondayContext.connected) {
        const items = [{ id: '5214736008', name: 'julitec Invoices Apple' }];
        setState(prevState => ({
            ...prevState,
            itemsForSelection: items.map(c => ({ value: c.id, label: c.name }))
        }));
        return;
    }

    const itemsLimit = 200;
    const itemsQueryPart = `
        items {
            id
            name
            group {
                id
            }
        }
    `;

    const boardData = await monday.api(`
        {
            complexity {
                query
                after
            }
            boards(ids: [${mondayContext.boardId}]) {
                id
                name
                item_terminology
                board_folder_id
                items_page(
                    limit: ${itemsLimit},
                ) {
                    cursor
                    ${itemsQueryPart}
                }
            }
        }
    `, { apiVersion: MONDAY_API_VERSION });

    const board = boardData.data.boards.find(b => b.id === mondayContext.boardId.toString());
    if (!board) {
        alert("Board not found");
        throw new Error("Board not found");
    }

    if (board.items_page && board.items_page.items) {
        setState(prevState => ({
            ...prevState,
            itemsForSelection: board.items_page.items.map(c => ({ value: c.id, label: c.name }))
        }));
    }
};
