import monday from './monday/monday';
import { MONDAY_API_VERSION } from './../constants/constants';

function getItemsQueryPart(withAssets) {
    var assetsPart = withAssets ? `assets { id public_url name }` : '';
    return `
      items {
        id
        name
        group {
            id
        }
        ${assetsPart}
        column_values {
          id
          type
          text
          value
          ... on MirrorValue {
            display_value
          }
          ... on BoardRelationValue {
            display_value
          }
          ... on DependencyValue {
            display_value
          }
        }
      }
`;
}

export async function loadBoardData(
    mondayContext,
    dataSource,
    selectedItem,
    useFilter,
    mondayFilterText) {
    if (!mondayContext.connected) {
        // TODO: Handle disconnected state
        return;
    }

    const itemsLimit = 100;
    let itemsQueryPart = getItemsQueryPart(true);

    let itemFilterText = '';
    if (dataSource === 'SingleItem' && selectedItem) {
        itemFilterText = `query_params: {ids: [${selectedItem.value}]}`;
    } else if (useFilter && mondayFilterText && dataSource !== 'SingleItem') {
        itemFilterText = `query_params: {rules: ${mondayFilterText}}`;
    }

    const boardData = await monday.api(
        `
    {
      complexity {
        query
        after
      }
      boards(ids: [${mondayContext.boardId}]) {
        id
        name
        item_terminology
        board_folder_id
        type
        description
        columns {
          title
          id
          type
          width
          settings_str
        }
        groups {
          id
          color
          title
        }
        owners {
          id
          name
          email
          phone
          photo_original
          photo_small
        }
        subscribers {
          id
          name
          email
          phone
          photo_original
          photo_small
        }
        items_page (
          limit: ${itemsLimit},
          ${itemFilterText}
        ) {
          cursor
          ${itemsQueryPart}
        }
      }
      me {
        id
        name
        email
        phone
        photo_original
        photo_small
        utc_hours_diff
        account {
          id
          name
          slug
        }
      }
    }
  `,
        { apiVersion: MONDAY_API_VERSION }
    );

    const board = boardData.data.boards.find(
        (b) => b.id === mondayContext.boardId.toString()
    );

    if (!board) {
        alert('Board not found');
        throw new Error('Board not found');
    }

    if (board.items_page && board.items_page.items && board.items_page.cursor) {
        let itemsData = board.items_page.items;
        let queryCursor = board.items_page.cursor;

        while (queryCursor) {
            if (itemsData.length >= 500) itemsQueryPart = getItemsQueryPart(false);

            const pageData = await monday.api(
                `
        query {
          complexity {
            query
            after
          }
          next_items_page (limit: ${itemsLimit}, cursor: "${queryCursor}") {
            cursor
            ${itemsQueryPart}
          }
        }
      `,
                { apiVersion: MONDAY_API_VERSION }
            );

            queryCursor = undefined;
            if (
                pageData &&
                pageData.data &&
                pageData.data.next_items_page &&
                pageData.data.next_items_page.items.length > 0
            ) {
                itemsData = itemsData.concat(pageData.data.next_items_page.items);
                queryCursor = pageData.data.next_items_page.cursor;
            }
        }
        board.items_page.items = itemsData;
    }

    return boardData;
}
