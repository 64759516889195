import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Dropdown, Button, EditableHeading, Flex, Tooltip, TextField, Checkbox, Icon } from "monday-ui-react-core";
import { Info } from "monday-ui-react-core/icons";
import axios from 'axios';
import DOMPurify from 'dompurify';

const TriggerDefinitionEdit = ({ id, columns, onClose }) => {
	const [triggerName, setTriggerName] = useState('Trigger Name');
	const [actionType, setActionType] = useState('SetState');
	const [redirectUrl, setRedirectUrl] = useState('');
	const [validForDays, setValidForDays] = useState();
	const [maxAllowedExecutions, setMaxAllowedExecutions] = useState();
	const [selectedColumnId, setSelectedColumnId] = useState('');
	const [newValueId, setNewValueId] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [error, setError] = useState(null);
	const [redirectUrlActive, setRedirectUrlActive] = useState(false);
	const [validForDaysActive, setValidForDaysActive] = useState(false);
	const [maxAllowedExecutionsActive, setMaxAllowedExecutionsActive] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(true);
				const response = await axios.get(`trigger?id=${id}&sessionToken=${window.mondaySessionToken}`);
				const { actionType, name, columnId, newStateId, redirectUrl, validForDays, maxAllowedExecutions } = response.data;
				setActionType(actionType);
				setTriggerName(name);
				setSelectedColumnId(columnId);
				setNewValueId(newStateId);
				setRedirectUrl(redirectUrl);
				setValidForDays(validForDays);
				setMaxAllowedExecutions(maxAllowedExecutions);
				setIsLoading(false);
				setRedirectUrlActive(!!redirectUrl);
				setValidForDaysActive(!!validForDays);
				setMaxAllowedExecutionsActive(!!maxAllowedExecutions);
			} catch (err) {
				setError(err);
				setIsLoading(false);
			}
		};

		if (id) {
			fetchData();
		}
	}, [id]);

	const getColumnSettings = useCallback((column) => {
		if (column.settings) return column.settings;
		if (!column.settings_str) return undefined;
		try {
			const settings = JSON.parse(column.settings_str);
			column.settings = settings;
			return settings;
		} catch (error) {
			console.error("Error parsing settings string:", error);
			return undefined;
		}
	}, []);

	const availableColumns = useMemo(() => columns
		.filter(c => c.type === (actionType === 'UpdateValue' ? 'numbers' : 'status'))
		.map(c => ({ value: c.id, label: c.title })), [columns, actionType]);

	const selectedColumn = useMemo(() => availableColumns.find(c => c.value === selectedColumnId), [availableColumns, selectedColumnId]);

	const selectedColumnRaw = useMemo(() => columns.find(c => c.id === selectedColumnId), [columns, selectedColumnId]);

	const statusValueOptions = useMemo(() => {
		if (selectedColumnRaw) {
			const settingsObj = getColumnSettings(selectedColumnRaw);
			if (settingsObj && settingsObj.labels) {
				return Object.entries(settingsObj.labels).map(([key, value]) => ({ value: key, label: value }));
			}
		}
		return [];
	}, [selectedColumnRaw, getColumnSettings]);

	const newValue = useMemo(() => statusValueOptions.find(v => v.value === newValueId), [statusValueOptions, newValueId]);
	const actionTypes = useMemo(() => [
		{ value: 'SetState', label: 'Set status' },
		{ value: 'UpdateValue', label: 'Update value' }
	], []);
	const selectedActionType = useMemo(() => actionTypes.find(at => at.value === actionType), [actionType, actionTypes]);

	const handleTriggerNameChange = useCallback((text) => {
		setTriggerName(text);
	}, []);

	const getDataForPOST = useCallback(() => {
		const mondayContext = window.mondayContext;
		const selectedColumn = columns.find(c => c.id === selectedColumnId);
		return {
			id: id,
			accountId: mondayContext.account?.id,
			boardId: mondayContext.boardId,
			workspaceId: mondayContext.workspaceId,
			userCountryCode: mondayContext.user.countryCode,
			userLanguage: mondayContext.user.currentLanguage,
			sessionToken: window.mondaySessionToken,
			name: DOMPurify.sanitize(triggerName),
			columnId: selectedColumn?.id,
			columnName: DOMPurify.sanitize(selectedColumn?.title),
			columnType: selectedColumn?.type,
			newStateId: newValue?.value,
			newStateName: DOMPurify.sanitize(newValue?.label),
			redirectUrl: redirectUrl,
			validForDays: validForDays,
			maxAllowedExecutions: maxAllowedExecutions,
			actionType: actionType,
			// TODO: UserName, BoardName, WorkspaceName
		};
	}, [id, columns, selectedColumnId, triggerName, newValue, redirectUrl, validForDays, maxAllowedExecutions, actionType]);

	const handleClickSave = useCallback(async () => {
		setIsSaving(true);
		const data = getDataForPOST();
		try {
			const response = await axios.post('trigger', data);
			console.log(response.data);
			setIsSaving(false);
			if (response.data.result) {
				onClose(response.data.template);
			} else {
				const errorMessage = 'Error saving trigger: ' + response.data.error;
				console.log(errorMessage);
				alert(errorMessage);
				setError(errorMessage);
			}
		} catch (error) {
			console.error('Error:', error);
			alert(error);
			setIsSaving(false);
		}
	}, [getDataForPOST, onClose]);

	const handleCancelClick = useCallback(() => {
		onClose();
	}, [onClose]);

	const handleSelectedColumnChange = useCallback((newSelectedColumn) => {
		setSelectedColumnId(newSelectedColumn?.value);
		setNewValueId(null);
	}, []);

	const handleActionTypeChange = useCallback((newActionType) => {
		setActionType(newActionType?.value);
		setSelectedColumnId(null);
	}, []);

	const handleNewValueChange = useCallback((newValue) => {
		setNewValueId(newValue?.value);
	}, []);

	const handleRedirectUrlChange = useCallback((newValue) => {
		setRedirectUrl(newValue);
	}, []);

	const handleRedirectUrlCheckboxChange = () => {
		setRedirectUrlActive(!redirectUrlActive);
	};

	const handleValidForDaysChange = useCallback((newValue) => {
		setValidForDays(newValue);
	}, []);

	const handleMaxAllowedExecutionsChange = useCallback((newValue) => {
		setMaxAllowedExecutions(newValue);
	}, []);

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>Error: {error.message}</div>;
	}

	const isValid = !!newValue || (!!selectedColumn && actionType === 'UpdateValue');
	const showNewValue = actionType === 'SetState';

	return (
		<div>
			<Tooltip content="Click to Edit">
				<EditableHeading
					onChange={handleTriggerNameChange}
					type="h2"
					value={triggerName}
				/>
			</Tooltip>
			<p></p>
			<p>Action to be executed when triggered:</p>
			<Flex>
				<Dropdown
					className="app-spirit-dropdown"
					onChange={handleActionTypeChange}
					value={selectedActionType}
					options={actionTypes}
					clearable={false}
					placeholder=""
				/>
				<span className="app-spirit-span-margin-right">of</span>
				<Dropdown
					className="app-spirit-dropdown-trigger-column"
					onChange={handleSelectedColumnChange}
					value={selectedColumn}
					options={availableColumns}
					clearable={false}
					placeholder="Choose column"
				/>
				{showNewValue && <><span className="app-spirit-span-margin-right">to</span>
					<Dropdown
						className="app-spirit-dropdown"
						onChange={handleNewValueChange}
						value={newValue}
						options={statusValueOptions}
						clearable={false}
						disabled={!selectedColumn}
						placeholder="Choose state"
					/></>}
			</Flex>
			<p></p>
			<Flex direction={Flex.directions.COLUMN} gap={8} align={Flex.align.START}>
				<Flex>
					<Tooltip content="By default, the user will be directed to a DocExport confirmation page after executing the trigger. Check this option and enter your target URL if you want the user to be redirected to a different place.">
						<Checkbox onChange={handleRedirectUrlCheckboxChange}
							checked={redirectUrlActive}
							className="app-spirit-checkbox-trigger"
						    label="Redirect URL" />													
						<TextField
							placeholder="https://www.yourdomain.com/confirmation"
							wrapperClassName="app-spirit-textfield-trigger"
							value={redirectUrl}
							disabled={!redirectUrlActive}
							onChange={handleRedirectUrlChange}
						/>
						<Icon className="app-spirit-icon-warning-in-tablecell" icon={Info} />
					</Tooltip>
				</Flex>
				<Flex>
					<Tooltip content="The period begins with the creation of the trigger QR code or trigger link.">
						<Checkbox onChange={() => setValidForDaysActive(!validForDaysActive)}
							checked={validForDaysActive}
							className="app-spirit-checkbox-trigger"
							label="Validity (days)" />
						<TextField
							placeholder="Number of days which the trigger is valid for, e.g. 14"
							wrapperClassName="app-spirit-textfield-trigger"
							disabled={!validForDaysActive}
							type={TextField.types.NUMBER}
							value={validForDays}
							onChange={handleValidForDaysChange}
						/>
					</Tooltip>
				</Flex>
				<Flex>
					<Tooltip content="Limit is valid for each trigger QR code or trigger link.">
						<Checkbox onChange={() => setMaxAllowedExecutionsActive(!maxAllowedExecutions)}
							checked={maxAllowedExecutionsActive}
							className="app-spirit-checkbox-trigger"
							label="Execution limit" />
						<TextField
							placeholder="Number of times the trigger can be executed, e.g. 2"
							wrapperClassName="app-spirit-textfield-trigger"
							disabled={!maxAllowedExecutionsActive}
							type={TextField.types.NUMBER}
							value={maxAllowedExecutions}
							onChange={handleMaxAllowedExecutionsChange}
						/>
					</Tooltip>
				</Flex>
			</Flex>
			<p></p>
			<Button disabled={!isValid} loading={isSaving} onClick={handleClickSave}>
				Save
			</Button>
			<Button kind={Button.kinds.SECONDARY} className="app-spirit-cancelbutton" onClick={handleCancelClick}>
				Cancel
			</Button>
		</div>
	);
};

export default TriggerDefinitionEdit;
