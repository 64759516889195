import React, { Component } from 'react';
import { Button, IconButton, Icon } from "monday-ui-react-core";
import { Delete } from "monday-ui-react-core/icons";
import { Download } from "monday-ui-react-core/icons";
import { Email } from "monday-ui-react-core/icons";
import FileUpload from './FileUpload';
import TemplateEdit from './TemplateEdit';
import axios from 'axios';
import { Table, TableCell, TableBody, TableHeader, TableHeaderCell, TableRow } from "monday-ui-react-core";
import { downloadExistingTemplate } from './fileDownloader';
import DOMPurify from 'dompurify';
import mondaySdk from "monday-sdk-js";

const TableEmptyState = () => <h1 style={{ textAlign: "center" }}>Empty State</h1>;

const TableErrorState = () => <h1 style={{ textAlign: "center" }}>Error State</h1>;

const monday = mondaySdk();
monday.setApiVersion("2023-10");

export class Templates extends Component {
    static displayName = Templates.name;

    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            loading: true,
            templateEditMode: false,
            templateEditId: null,
            isBoardOwner: props.isBoardOwner
        };
        this.mondayContext = window.mondayContext;
        this.towerAppInfos = window.towerAppInfos;
        this.deleteTemplate = this.deleteTemplate.bind(this);
        this.fileUploadComplete = this.fileUploadComplete.bind(this);
        this.downloadTemplate = this.downloadTemplate.bind(this);
        this.handleAddHtmlTemplate = this.handleAddHtmlTemplate.bind(this);
        this.handleNewTemplateClose = this.handleNewTemplateClose.bind(this);
        this.handleAddWordTemplate = this.handleAddWordTemplate.bind(this);
        this.handleEditHtmlTemplate = this.handleEditHtmlTemplate.bind(this);
    }

    componentDidMount() {
        this.populateTemplateData();
    }

    async downloadTemplate(template) {
        await downloadExistingTemplate(template, window.mondaySessionToken);
    }

    fileUploadComplete(success, message, responseData) {
        if (success) {
            monday.execute("notice", {
                message: "You have successfully replaced the template",
                type: "success",
                timeout: 3000,
            });
        }

        if (!success)
            alert(message);
    }

    async handleEditHtmlTemplate(event, id) {
        if (!this.state.isBoardOwner)
            return;
        if (!this.towerAppInfos || this.towerAppInfos.hasEmailTemplates === false) {
            alert('Please upgrade to the Professional plan to use email templates!');
            return;
        }
        this.setState({ templateEditMode: true, templateEditId: id });
    }

    async deleteTemplate(event, id) {
        //console.log('Delete template ' + id);
        try {
            const response = await axios.delete('template?Id=' + id + '&SessionToken=' + window.mondaySessionToken);
            console.log('Success:', response.data);
            await this.populateTemplateData();
        } catch (error) {
            console.error('Error:', error);
        }
    }

    renderNewTable(templates) {

        var columns = [{
            id: "download",
            title: "",
            width: 40,
            //loadingStateType: "circle"
        }, {
            id: "templateType",
            title: "",
            width: 40,
            loadingStateType: "circle"
        }, {
            id: "name",
            title: "Name",
            loadingStateType: "long-text"
        }, {
            id: "dataSource",
            title: "Type",
            width: 100,
            loadingStateType: "medium-text"
        }, {
            id: "lastChangedOn",
            title: "Date",
            width: 160,
            //infoContent: "Info content for the status column",
            loadingStateType: "medium-text"
        }, {
            id: "replace",
            title: "",
            width: 90,
            loadingStateType: "circle"
        }, {
            id: "delete",
            title: "",
            width: 40,
            loadingStateType: "circle"
        }];

        var tableDataState = {
            isLoading: this.state.loading
        };

        return (
            <Table errorState={<TableErrorState />} emptyState={<TableEmptyState />} columns={columns} dataState={tableDataState}>
                <TableHeader>
                    {columns.map((headerCell, index) => <TableHeaderCell key={index} title={headerCell.title} />)}
                </TableHeader>
                <TableBody>
                    {templates.map(rowItem => <TableRow key={rowItem.id}>
                        <TableCell className="app-spirit-tablecell-with-button" >
                            {rowItem.templateType !== 'Html' && <IconButton
                                ariaLabel="Download"
                                size={IconButton.sizes.SMALL}
                                icon={Download}
                                onClick={() => this.downloadTemplate(rowItem)}
                            />}
                        </TableCell>
                        <TableCell>
                            {rowItem.templateType === 'Html' && <Icon icon={Email} iconSize={24} onClick={(e) => this.handleEditHtmlTemplate(e, rowItem.id)} />}
                            {rowItem.templateType !== 'Html' && <img
                                className="app-spirit-img-in-tablecell"
                                src={`/DOCX.png`}
                                alt={rowItem.templateType} />}
                        </TableCell>
                        <TableCell>{rowItem.name}</TableCell>
                        <TableCell>{rowItem.dataSource}</TableCell>
                        <TableCell>{rowItem.lastChangedOn}</TableCell>
                        <TableCell className="app-spirit-tablecell-with-button" >
                            {this.state.isBoardOwner && rowItem.templateType !== 'Html' && (
                                <FileUpload Destination={rowItem.id}
                                    buttonText="Replace"
                                    buttonKind={Button.kinds.SECONDARY}
                                    buttonSize={Button.sizes.SMALL}
                                    onUploadComplete={this.fileUploadComplete}></FileUpload>
                            )}
                            {this.state.isBoardOwner && rowItem.templateType === 'Html' && (
                                <Button
                                    kind={Button.kinds.SECONDARY}
                                    size={Button.sizes.SMALL}
                                    onClick={(e) => this.handleEditHtmlTemplate(e, rowItem.id)}>Edit</Button>
                            )}
                        </TableCell>
                        <TableCell className="app-spirit-tablecell-with-button" >
                            {this.state.isBoardOwner && (
                                <IconButton
                                    ariaLabel="Delete"
                                    size={IconButton.sizes.SMALL}
                                    icon={Delete}
                                    onClick={(e) => this.deleteTemplate(e, rowItem.id)}
                                />
                            )}
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        );
    }

    handleAddHtmlTemplate() {
        if (!this.towerAppInfos || this.towerAppInfos.hasEmailTemplates === false) {
            alert('Please upgrade to the Professional plan to use email templates!');
            return;
        }
        this.setState({ templateEditMode: true, templateEditId: null });
    }

    handleAddWordTemplate() {
        let text = 'Please go to "Generate Document" tab to add a new Word template.';
        if (this.props.isReportGenerator)
            text = 'Please go to "Generate Report" tab to add a new Word template.';
        alert(text);
    }

    handleNewTemplateClose() {
        this.setState({ templateEditMode: false });
        this.populateTemplateData();
    }

    render() {
        if (this.state.templateEditMode) {
            return (
                <div>
                    <TemplateEdit id={this.state.templateEditId} onClose={this.handleNewTemplateClose}></TemplateEdit>
                </div>
            );
        }

        let contents = this.renderNewTable(this.state.templates);

        return (
            <div>
                <h3 id="tableLabel">Templates</h3>                
                <Button onClick={this.handleAddWordTemplate}>Add Word Template</Button>
                {!this.props.isReportGenerator && <Button className="app-spirit-cancelbutton" onClick={this.handleAddHtmlTemplate}>Add Email Template</Button>}
                <p></p>
                {contents}
            </div>
        );
    }

    async populateTemplateData() {
        var timeZoneOffset = this.mondayContext.user.timeZoneOffset;
        if (timeZoneOffset === undefined)
            timeZoneOffset = 0;
        const response = await fetch('template/list?BoardId=' + this.mondayContext.boardId +
            '&UserCountryCode=' + DOMPurify.sanitize(this.mondayContext.user.countryCode) +
            '&UserLanguageCode=' + DOMPurify.sanitize(this.mondayContext.user.currentLanguage) +
            '&TimeZoneOffset=' + timeZoneOffset +
            '&SessionToken=' + window.mondaySessionToken);
        const data = await response.json();
        //console.log(data);
        this.setState({ templates: data, loading: false });
    }
}
