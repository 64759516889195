import React, { Component } from 'react';
import { Button, ButtonGroup } from "monday-ui-react-core";
import { Loader, EditableHeading, Tooltip } from "monday-ui-react-core";
import { Checkbox } from "monday-ui-react-core";
import { Dropdown } from "monday-ui-react-core";
import { Flex } from "monday-ui-react-core";
import { ExpandCollapse } from "monday-ui-react-core";
import mondaySdk from "monday-sdk-js";
import axios from 'axios';
import DOMPurify from 'dompurify';
import { convertMondayFilterToGraphQLFilter } from './MondayFilterHelper';

const monday = mondaySdk();
monday.setApiVersion("2023-10");

export class ChartDefinitionEdit extends Component {
    static displayName = ChartDefinitionEdit.name;

    constructor(props) {
        super(props);
        this.chartTypes = [
            { value: "Bar", label: "Bar" },
            { value: "Bar3D", label: "Bar 3D" },
            { value: "Bubble", label: "Bubble" },
            { value: "Column", label: "Column" },
            { value: "Column3D", label: "Column 3D" },
            { value: "Doughnut", label: "Donut" },
            { value: "Pie", label: "Pie" },
            { value: "Pie3D", label: "Pie 3D" },
            { value: "Area", label: "Area" },
            { value: "Line", label: "Line" },
            //{ value: "Line3D", label: "Line 3D" },
            { value: "Radar", label: "Radar" }];

        this.state = {
            chartName: 'Chart name',
            allColumns: [],
            xAxisColumns: [],
            yAxisColumns: [],
            yAxisFunction: 'Sum',
            selectedXAxisColumn: null,
            selectedXAxisGroupBy: null,
            selectedChartType: this.chartTypes[0],
            loading: true,            
            isRenderingPreview: false,
            previewPdfUrl: null,
            placeholderName: null,
            isSaving: false,
            useFilter: true,
            mondayFilterText: null,
            yAxisSelectedColumns: []
        };
        this.titleTimeoutId = null;
        this.needsPreviewUpdate = false;
        this.mondayFilterText = null;
        this.isRenderingPreview = false;
        this.mondayContext = window.mondayContext;
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleXAxisColumnChange = this.handleXAxisColumnChange.bind(this);
        this.handleChartTypeChange = this.handleChartTypeChange.bind(this);
        this.handleClickRefreshPreview = this.handleClickRefreshPreview.bind(this);
        this.handleYAxisFunctionChange = this.handleYAxisFunctionChange.bind(this);
        this.handleYAxisColumnCheckboxChange = this.handleYAxisColumnCheckboxChange.bind(this);
        this.handleChartNameChange = this.handleChartNameChange.bind(this);
        this.handleSelectedXAxisGroupByChange = this.handleSelectedXAxisGroupByChange.bind(this);
        this.handleUseFilterCheckboxChange = this.handleUseFilterCheckboxChange.bind(this);
    }

    componentDidMount() {
        this.loadColumnData();

        monday.listen('filter', res => {
            //console.log(res);
            var mondayFilterText = null;
            if (res.data.rules && res.data.rules.length > 0) {
                mondayFilterText = convertMondayFilterToGraphQLFilter(res.data.rules);
            }
            if (mondayFilterText !== this.mondayFilterText) {
                this.setState({ mondayFilterText: mondayFilterText });
                this.mondayFilterText = mondayFilterText;
                if (this.state.useFilter)
                    this.updateChartPreviewAsync();
            }
        });
    }

    handleClickRefreshPreview() {
        this.updateChartPreview();
    }

    handleYAxisFunctionChange(value, name) {
        this.setState({ yAxisFunction: value });
        this.updateChartPreviewAsync();
    //    console.log(value);
    //    console.log(name);
    }

    handleSelectedXAxisGroupByChange(groupBy) {
        this.setState({ selectedXAxisGroupBy: groupBy });
        this.updateChartPreviewAsync();
    }

    handleUseFilterCheckboxChange() {
        this.setState({ useFilter: !this.state.useFilter });
        this.updateChartPreviewAsync();        
    }

    handleYAxisColumnCheckboxChange(column) {
        var yAxisSelectedColumns = this.state.yAxisSelectedColumns;

        const columnIndex = yAxisSelectedColumns.findIndex(c => c.id === column.id);

        if (columnIndex === -1) {
            // Column not found, add it
            yAxisSelectedColumns.push(column);
        } else {
            // Column found, remove it
            yAxisSelectedColumns.splice(columnIndex, 1);
        }

        this.setState({ yAxisSelectedColumns: yAxisSelectedColumns });
        this.updateChartPreviewAsync();
    }

    existsYAxisColumn(column) {
        return this.state.yAxisSelectedColumns.findIndex(c => c.id === column.id) !== -1;
    }

    handleChartNameChange(text) {
        this.setState({ chartName: text });

        // Execute only after 500ms of no change
        //this.updateChartPreviewAsync();

        // Clear the previous timeout (if any)
        if (this.titleTimeoutId) {
            clearTimeout(this.titleTimeoutId);
        }

        // Set a new timeout
        this.titleTimeoutId = setTimeout(() => {
            this.updateChartPreviewAsync();
        }, 500); // 500ms delay
    }

    handleCancelClick() {
        this.props.onClose();
    }

    async handleClickSave() {
        this.setState({ isSaving: true });
        const data = this.getDataForPOST();
        try {
            const response = await axios.post('chartdefinition', data);
            console.log(response.data);
            if (response.data.result) {
                this.setState({ isSaving: false });
                this.props.onClose(response.data.chartDefinition);
            } else {
                var errorMessage = 'Error saving chart: ' + response.data.error;
                console.log(errorMessage);
                alert(errorMessage);
                this.setState({ isSaving: false });
            }
        } catch (error) {
            console.error('Error:', error);
            alert(error);
            this.setState({ isSaving: false });
        }
    }

    loadColumnData() {
        if (!this.mondayContext.connected) {
            this.mondayColumnData = {
                boards: [{
                    id: '1',
                    name: 'Board name',
                    columns: [
                        { id: 'status_11', title: 'Status', type: 'status' },
                        { id: 'date4', title: 'Payment due', type: 'date' },
                        { id: 'subitems_amount', title: 'Subtotal', type: 'mirror' },
                        { id: 'numbers_15', title: 'Amount', type: 'numbers' }]
                }]
            };
            this.setColumns(this.mondayColumnData.boards[0]);

            return;
        }
        monday.api(this.GetColumnQuery(this.mondayContext.boardId), { apiVersion: '2023-10' }).then(columnData => {
            //console.log(columnData);
            this.mondayColumnData = columnData.data;
            this.setColumns(this.mondayColumnData.boards[0]);
        });
    }

    setColumns(board) {
        var allColumns = board.columns;
        var xAxisColumns = allColumns
            .filter(c => c.type === "date" || c.type === "status" || c.type === "people")
            .map(c => ({ value: c.id, label: c.title, type: c.type }));

        var yAxisColumns = allColumns
            .filter(c => c.type === "numbers" || c.type === "mirror" || c.type === "formula");
        var countItemsColumn = {
            type: "numbers",
            id: "*CountItems*",
            title: "Count items"
        };
        yAxisColumns.unshift(countItemsColumn);

        var selectedXAxisColumn = this.state.selectedXAxisColumn;
        if (!selectedXAxisColumn && xAxisColumns.length > 0) 
            selectedXAxisColumn = xAxisColumns[0];

        this.setState({
            allColumns: allColumns,
            xAxisColumns: xAxisColumns,
            yAxisColumns: yAxisColumns,
            selectedXAxisColumn: selectedXAxisColumn,
            yAxisSelectedColumns: [countItemsColumn],
            loading: false
        });
        this.updateChartPreviewAsync();
    }

    GetColumnQuery(boardId) {
        return `
query {
  complexity {
    query
    after
  }
  boards (ids: ${boardId}) { 
    id      
    name    
    columns {
        id
        title
        type
        settings_str
    }
  }
        }`;
    }

    handleXAxisColumnChange(selectedColumn) {
        this.setState({ selectedXAxisColumn: selectedColumn });
        this.updateChartPreviewAsync();
    }

    handleChartTypeChange(selectedChartType) {
        this.setState({ selectedChartType: selectedChartType });
        this.updateChartPreviewAsync();
    }

    updateChartPreviewAsync() {
        var self = this;
        setTimeout(function () {
            self.updateChartPreview();
        }, 10);
    }

    async updateChartPreview() {
        if (!this.state.selectedXAxisColumn)
            return;
        if (this.isRenderingPreview) {
            this.needsPreviewUpdate = true;
            return;
        }

        this.isRenderingPreview = true;
        this.setState({ isRenderingPreview: true });        
        const data = this.getDataForPOST();
        var loadBoardData = true;
        var newFilterText = this.mondayFilterText;
        if (!this.state.useFilter)
            newFilterText = null;
        if (this.boardData) {
            if (this.boardDataFilterText === newFilterText)
                loadBoardData = false;
        }
        if (loadBoardData) {
            console.log('Loading board data ' + newFilterText);
            this.boardDataFilterText = newFilterText;
            this.boardData = await this.loadBoardData();
        }
        if (this.boardData)
            data.boardData = this.boardData.data;
        this.startWaitForDataRequest(data.requestId);
        try {
            const response = await axios.post('chartdefinition/PreviewChart', data);
            //console.log(response.data);
            this.isRenderingPreview = false;
            if (response.data.result) {
                this.setState({
                    isRenderingPreview: false,
                    previewPdfUrl: response.data.previewPdfUrl,
                    placeholderName: response.data.placeholderName
                });
                if (this.needsPreviewUpdate) {
                    this.needsPreviewUpdate = false;
                    this.updateChartPreviewAsync();
                }
            } else {
                var errorMessage = 'Error generating document: ' + response.data.error;
                console.log(errorMessage);
                alert(errorMessage);
                this.setState({ isRenderingPreview: false });
            }
        } catch (error) {
            console.error('Error:', error);
            alert(error);
            this.setState({ isRenderingPreview: false });
            this.isRenderingPreview = false;
        }
    }

    startWaitForDataRequest(requestId, queryResult = undefined) {
        var waitForDataRequestData = {
            boardId: this.mondayContext.boardId,
            sessionToken: window.mondaySessionToken,
            requestId: requestId,
            queryResult: JSON.stringify(queryResult)
        };
        axios.post('document/WaitForDataRequest', waitForDataRequestData)
            .then(response => {
                console.log(response);
                if (response.data.action === 'Query') {
                    monday.api(response.data.query, { apiVersion: '2023-10' }).then(queryResult => {
                        console.log(queryResult);
                        this.startWaitForDataRequest(requestId, queryResult.data);
                    })
                }
            })
            .catch(error => {
                console.error('Error with WaitForDataRequest:', error);
            });
    }

    generateUniqueID() {
        return 'id-' + Math.random().toString(36).substr(2, 9) + '-' + Date.now();
    }

    getDataForPOST() {
        const data = {
            requestId: this.generateUniqueID(),
            accountId: this.mondayContext.account?.id,
            boardId: this.mondayContext.boardId,
            workspaceId: this.mondayContext.workspaceId,
            userCountryCode: this.mondayContext.user.countryCode,
            userLanguage: this.mondayContext.user.currentLanguage,
            sessionToken: window.mondaySessionToken,
            name: DOMPurify.sanitize(this.state.chartName),
            xAxisColumnId: DOMPurify.sanitize(this.state.selectedXAxisColumn?.value),
            xAxisColumnName: DOMPurify.sanitize(this.state.selectedXAxisColumn?.label),
            xAxisColumnType: DOMPurify.sanitize(this.state.selectedXAxisColumn?.type),
            xAxisGroupBy: DOMPurify.sanitize(this.state.selectedXAxisGroupBy?.value),
            yAxisFunction: this.state.yAxisFunction,
            yAxis: this.state.yAxisSelectedColumns.map(column => {
                return { columnId: column.id, columnName: column.title };
            }),
            chartType: DOMPurify.sanitize(this.state.selectedChartType?.value),
        };
        if (this.state.useFilter && this.state.mondayFilterText) {
            data.dataFilter = this.state.mondayFilterText;
        }
        return data;
    }

    async loadBoardData() {
        if (!this.mondayContext.connected) {
            // TODO: What now?
            return;
        }

        var itemsLimit = 100;
        var itemsQueryPart = `
      items {
        id
        name
        group {
            id
        }
        column_values {
          id
          type
          text
          value
          ... on MirrorValue {
            display_value
          }
          ... on BoardRelationValue {
            display_value
          }
          ... on DependencyValue {
            display_value
          }
        }
      }
`;

        var itemFilterText = "";
        if (this.state.useFilter && this.mondayFilterText)
            itemFilterText = `query_params: {rules: ${this.mondayFilterText}}`;

        // Complexity with limit:500 1529580 (without assets: 24580)
        // Complexity with limit:100  309986 (without assets:  8986)
        var boardData = await monday.api(`
{
  complexity {
    query
    after
  }
  boards(ids: [${this.mondayContext.boardId}]) {
    id
    name
    columns {
      title
      id
      type
      settings_str
    }
    groups {
      id
      color
      title
    }
    owners {
      id
      name
      email
      phone
      photo_original
      photo_small
    }
    subscribers {
      id
      name
      email
      phone
      photo_original
      photo_small
    }
    items_page(
      limit: ${itemsLimit},
      ${itemFilterText}
    ) {
      cursor
      ${itemsQueryPart}
    }
  }
  me {
    id
    name
    email
    phone
    photo_original
    photo_small
    utc_hours_diff
    account {
      id
      name
      slug
    }
  }
}
`, { apiVersion: '2023-10' });

        // TODO: Handle There was an error in response from monday.com graphql API:  ["Complexity budget exhausted, query cost 2531544 budget remaining 1445383 out of 5000000 reset in 18 seconds"]

        var board = boardData.data.boards.find(b => b.id === this.mondayContext.boardId.toString());
        if (!board) {
            alert("Board not found");
            throw new Error("Board not found");
        }
        if (board.items_page && board.items_page.items && board.items_page.cursor) {
            var itemsData = board.items_page.items;
            var queryCursor = board.items_page.cursor;
            while (queryCursor) {
                //console.log("Query cursor: " + queryCursor);
                // TODO: Check complexity limit first -> Slow down?
                // Complexity with limit:100 12206 (without assets: 166)                
                var pageData = await monday.api(`
query {
  complexity {
    query
    after
  }
  next_items_page (limit: ${itemsLimit}, cursor: "${queryCursor}") {
    cursor
    ${itemsQueryPart}
  }
}
`, { apiVersion: '2023-10' });
                //console.log(pageData);
                queryCursor = undefined;
                if (pageData && pageData.data && pageData.data.next_items_page && (pageData.data.next_items_page.items.length > 0)) {
                    itemsData = itemsData.concat(pageData.data.next_items_page.items);
                    queryCursor = pageData.data.next_items_page.cursor;
                }
            }
            board.items_page.items = itemsData;
        }

        return boardData;
    }

    renderPreview() {
        const { previewPdfUrl, isRenderingPreview } = this.state;

        var loader = null;
        if (isRenderingPreview)
            loader = (<Loader wrapperClassName="app-spirit-chart-preview-loader" size={40} />);
        //if (!previewPdfUrl) {
        //    if (isRenderingPreview)
        //        return loader;
        //    return null;
        //}
        var img = null;
        if (previewPdfUrl) {
            var fullPreviewPdfUrl = previewPdfUrl + "&sessionToken=" + window.mondaySessionToken;
            img = (<img className="app-spirit-chart-preview-img" src={fullPreviewPdfUrl} alt="Preview" />);
        }

        return (
            <div>
                {img}
                {loader}
            </div>
        //    <iframe
        //        src={fullPreviewPdfUrl}
        //        width="100%"
        //        height="600px"
        //        style={{ border: 'none' }}
        //        title="PDF Viewer"
        //    />
        );
    }

    renderDateUnitDropdown() {
        if (!this.state.selectedXAxisColumn)
            return null;
        if (this.state.selectedXAxisColumn.type !== 'date')
            return null;
        var dateUnitOptions = [
            { value: "day", label: "Day" },
            { value: "week", label: "Week" },
            { value: "month", label: "Month" },
            { value: "quarter", label: "Quarter" },
            { value: "year", label: "Year" }];
        return (
            <div>
                <br /><span>Group by</span>
                <Dropdown
                    className="app-spirit-dropdown"
                    onBlur={function noRefCheck() { }}
                    onChange={this.handleSelectedXAxisGroupByChange}
                    onClear={function noRefCheck() { }}
                    onFocus={function noRefCheck() { }}
                    onInputChange={function noRefCheck() { }}
                    onMenuClose={function noRefCheck() { }}
                    onMenuOpen={function noRefCheck() { }}
                    onOptionRemove={function noRefCheck() { }}
                    onOptionSelect={function noRefCheck() { }}
                    openMenuOnFocus={function noRefCheck() { }}
                    value={this.state.selectedXAxisGroupBy}
                    options={dateUnitOptions}
                    clearable={false}
                    placeholder="Please select a unit"
                />
            </div>
        );
    }

    render() {
        if (this.state.loading)  // TODO: Loading animation
            return (<p><em>Loading...</em></p>);

        let chartPreview = this.renderPreview();
        let dateUnitDropdown = this.renderDateUnitDropdown();
        const shouldRenderApplyFilterCheckbox = this.state.mondayFilterText;
        return (
            <div>
                <Tooltip content="Click to Edit">
                    <EditableHeading
                        onChange={this.handleChartNameChange}
                        type="h2"
                        value={this.state.chartName}
                    />
                </Tooltip>
                <Flex align={Flex.align.START} gap={32}>
                    <div className="app-spirit-chart-preview-column">
                        {chartPreview}
                    </div>
                    <div className="app-spirit-chart-settings-column">
                        <ExpandCollapse title="Chart type" defaultOpenState={true}>
                            <Dropdown
                                className="app-spirit-dropdown"
                                onBlur={function noRefCheck() { }}
                                onChange={this.handleChartTypeChange}
                                onClear={function noRefCheck() { }}
                                onFocus={function noRefCheck() { }}
                                onInputChange={function noRefCheck() { }}
                                onMenuClose={function noRefCheck() { }}
                                onMenuOpen={function noRefCheck() { }}
                                onOptionRemove={function noRefCheck() { }}
                                onOptionSelect={function noRefCheck() { }}
                                openMenuOnFocus={function noRefCheck() { }}
                                value={this.state.selectedChartType}
                                options={this.chartTypes}
                                clearable={false}
                                placeholder="Please select a type"
                            />
                        </ExpandCollapse>
                        <p></p>
                        <ExpandCollapse title="X Axis" defaultOpenState={true}>
                            <Dropdown
                                className="app-spirit-dropdown"
                                onBlur={function noRefCheck() { }}
                                onChange={this.handleXAxisColumnChange}
                                onClear={function noRefCheck() { }}
                                onFocus={function noRefCheck() { }}
                                onInputChange={function noRefCheck() { }}
                                onMenuClose={function noRefCheck() { }}
                                onMenuOpen={function noRefCheck() { }}
                                onOptionRemove={function noRefCheck() { }}
                                onOptionSelect={function noRefCheck() { }}
                                openMenuOnFocus={function noRefCheck() { }}
                                value={this.state.selectedXAxisColumn}
                                options={this.state.xAxisColumns}
                                clearable={false}
                                placeholder="Please select a column"
                            />
                            {dateUnitDropdown}
                        </ExpandCollapse>
                        <p></p>
                        <ExpandCollapse title="Y Axis" defaultOpenState={true}>
                            {this.state.yAxisColumns.map(c =>
                                <Checkbox key={c.id} onChange={() => this.handleYAxisColumnCheckboxChange(c)} checked={this.existsYAxisColumn(c)} label={c.title} />
                            )}
                            <br /><hr /><br />
                            <span>Calculation function</span>
                            <ButtonGroup
                                className="app-spirit-button-group"
                                onSelect={this.handleYAxisFunctionChange}
                                options={[
                                    {
                                        text: 'Sum',
                                        value: 'Sum'
                                    },
                                    {
                                        text: 'Average',
                                        value: 'Average'
                                    },
                                    {
                                        text: 'Median',
                                        value: 'Median'
                                    },
                                    {
                                        text: 'Min',
                                        value: 'Min'
                                    },
                                    {
                                        text: 'Max',
                                        value: 'Max'
                                    }
                                ]}
                                value={this.state.yAxisFunction}
                            />
                        </ExpandCollapse>
                        <p></p>
                        <div>
                            <Tooltip content="Use board filters to narrow down the items you want to be included in your documents. Click on 'Filter' in the header bar of this board view to select and save your filter settings.">
                                <Checkbox onChange={this.handleUseFilterCheckboxChange}
                                    disabled={!shouldRenderApplyFilterCheckbox}
                                    checked={shouldRenderApplyFilterCheckbox && this.state.useFilter}
                                    label="Apply board filter" />
                            </Tooltip>
                            <p></p>
                        </div>
                        <Button className="app-spirit-cancelbutton" onClick={this.handleClickSave}>
                            Save
                        </Button>
                        <Button kind={Button.kinds.SECONDARY} className="app-spirit-cancelbutton" onClick={this.handleCancelClick}>
                            Cancel
                        </Button>
                    </div>
                </Flex>
            </div>
        );
    }
}
